import LineLeft from "./LineComponent/LineLeft";
import {SelectDic,UpDataOneLine,UpDataLine} from'../../RequestPort/equipment/line'
export default {
    name: "line1",
    components:{LineLeft},
    data(){
        return {
            openPieline:[],
            LeftShiftBool:false,
            LineForm:{

            },
            AddLineBool:false,
            SelectLineData:'1',
            CanSeeDetailBool:false,
            Map:{},
            stressLevelArray:[
                {name:'高压',value:0},
                {name:'低压',value:1},
                {name:'中压',value:2},
                {name:'次高压',value:3},
            ],
            buriedTypeArray:[
                {name:'架空',value:0},
                {name:'地下',value:1},
            ],
            LineDiameterArray:[],
            gasTypeArray:[
                {name:'煤气',value:0},
                {name:'天然气',value:1},
                {name:'其他',value:2},
            ],
            materialArray:[
                {name:'铸铁',value:0},
                {name:'其他',value:1},
                {name:'PE',value:2},
                {name:'PV3',value:3},
            ],
            useStatusArray:[
                {name:'在建',value:0},
                {name:'停用',value:1},
                {name:'在用',value:2},
                {name:'规划',value:3},
            ],
            LinePointArray:[],
            OnDrawLine:false,
            mouseTool:{},
            LinePositionArray:[],
            DrawStatus:false,
            polyEditor:{},
            ChangeStatus:false,
            ResetData:[],
        }
    },
    methods:{
        ChangeNetWork(e){
            this.Map.clearMap()
            e.forEach((item,index)=>{
                if(item.ShowLine){
                    item.linePreviews.forEach((item1,index1)=>{
                        var polyline = new AMap.Polyline({
                            path:item1.coordinate,
                            strokeColor:item1.styleColor,
                            strokeOpacity: 1,
                            strokeWeight:3,
                            strokeStyle: "solid",
                        })
                        polyline.setMap(this.Map)
                    })
                }
            })
        },
        ChangeLine(){
            if(this.DrawStatus){
                this.$message.error('请先退出绘制状态')
                return
            }
            this.ChangeStatus = !this.ChangeStatus
            if(this.ChangeStatus){
                this.Map.clearMap()
                let polylineArray = this.LinePositionArray.concat()
                var polyline = new AMap.Polyline({
                    path:polylineArray,
                    strokeColor:this.LineForm.styleColor,
                    strokeOpacity: 1,
                    strokeWeight:3,
                    strokeStyle: "solid",
                })
                polyline.setMap(this.Map)
                this.polyEditor = new AMap.PolyEditor(this.Map,polyline)
                this.polyEditor.open()
                this.polyEditor.on('adjust', (event)=>{
                    this.LinePositionArray = []
                    event.target.w.path.forEach((item,index)=>{
                        let onePointPosition = [item.R,item.Q]
                        this.LinePositionArray.push(onePointPosition)
                    })
                })
            }else{
                this.polyEditor.close()
            }

        },
        CloseOneLineFun(){
            this.LineForm = {}
            this.Map.clearMap()
        },
        SaveLineFun(){
            UpDataLine({id:this.LineForm.id,coordinate:this.LinePositionArray}).then((e)=>{
                this.$refs.LineLeft.LineDetailListAjax()
                this.$refs.LineLeft.RequestPiplineGroup()
                this.$message.success('添加成功')
            })
        },
        ToTextLtd(e){
            return e.row[0].toFixed(5)
        },
        ToTextLat(e){
            return e.row[1].toFixed(5)
        },
        DrawLine(){
            if(this.ChangeStatus){
                this.$message.error('请先退出修改状态')
                return
            }
            this.DrawStatus = !this.DrawStatus
            if(this.DrawStatus){
                this.mouseTool = new AMap.MouseTool(this.Map)
                this.mouseTool.polyline({
                    strokeColor:this.LineForm.styleColor,
                    strokeOpacity: 1,
                    strokeWeight:3,
                    strokeStyle: "solid",
                })
                this.OnDrawLine = true
                this.mouseTool.on('draw', (event)=>{
                    event.obj.getPath().forEach((item,index)=>{
                        if(JSON.stringify(this.LinePositionArray).indexOf(JSON.stringify([item.R,item.Q])) == -1){
                            this.LinePositionArray.push([item.R,item.Q])
                        }
                    })
                })
            }else{
                this.mouseTool.close()
                this.OnDrawLine = false
            }
        },
        SaveLineData(){
            UpDataOneLine(this.LineForm).then((e)=>{
                this.Map.clearMap()
                let polylineArray = this.LinePositionArray.concat()
                var polyline = new AMap.Polyline({
                    path:polylineArray,
                    strokeColor:this.LineForm.styleColor,
                    strokeOpacity: 1,
                    strokeWeight:3,
                    strokeStyle: "solid",
                })
                polyline.setMap(this.Map)
                this.$message.success('保存成功')
            })
        },
        ClickLineData(e){
            this.LineForm = e
            this.LineForm.diameter = Number(e.diameter)
            this.LinePositionArray = e.coordinate
            this.ResetData = e.coordinate.concat()
            this.Map.clearMap()
            let polylineArray = this.LinePositionArray.concat()
            var polyline = new AMap.Polyline({
                path:polylineArray,
                strokeColor:this.LineForm.styleColor,
                strokeOpacity: 1,
                strokeWeight:3,
                strokeStyle: "solid",
            })
            polyline.setMap(this.Map)
        },
        CanSeeLineDetail(){
            this.CanSeeDetailBool = !this.CanSeeDetailBool
        },
        DeletePoint(e){
            this.LinePositionArray.splice(e.$index,1)
            this.Map.clearMap()
            let polylineArray = this.LinePositionArray.concat()
            var polyline = new AMap.Polyline({
                path:polylineArray,
                strokeColor:this.LineForm.styleColor,
                strokeOpacity: 1,
                strokeWeight:3,
                strokeStyle: "solid",
            })
            polyline.setMap(this.Map)
        },
        resetLineFun(){
            this.LinePositionArray = this.ResetData.concat()
            this.Map.clearMap()
            let polylineArray = this.LinePositionArray.concat()
            var polyline = new AMap.Polyline({
                path:polylineArray,
                strokeColor:this.LineForm.styleColor,
                strokeOpacity: 1,
                strokeWeight:3,
                strokeStyle: "solid",
            })
            polyline.setMap(this.Map)
        },
        MapClickFun(e){
            if(this.LinePositionArray.length>0&&this.OnDrawLine == true ){
                var polyline = new AMap.Polyline({
                    path: [[e.lnglat.R,e.lnglat.Q],this.LinePositionArray[this.LinePositionArray.length-1]],
                    strokeColor:this.LineForm.styleColor,
                    strokeOpacity: 1,
                    strokeWeight:3,
                    strokeStyle: "solid",
                })
                polyline.setMap(this.Map)
                this.LinePositionArray.push([e.lnglat.R,e.lnglat.Q])
            }
        },
    },
    mounted() {
        this.Map = new AMap.Map("container");
        SelectDic({parentCode:1000240001}).then((e)=>{
            this.LineDiameterArray = e.data
        })
        this.Map = new AMap.Map("LineMap", {
            center: [116.434381, 39.898515],
            zoom: 14
        });
        this.Map.on('click',this.MapClickFun)
    },
}