import axios from '@/common/js/request'
// /zypatrol/network/page
export function GetPiplineGroup(params){
    const GetPiplineGroup = axios({
        url: 'patrol/network/page',
        method: 'get',
        params
    })
    return GetPiplineGroup
}
///zypatrol/network/status/update
export function UpPipStatus(data){
    const UpPipStatus = axios({
        url: 'patrol/network/status/update',
        method: 'post',
        data
    })
    return UpPipStatus
}
// /zypatrol/line/list
export function LineDetailList(params){
    const LineDetailList = axios({
        url: 'patrol/line/list',
        method: 'get',
        params
    })
    return LineDetailList
}
// /zypatrol/line/basic/update
export function UpDataOneLine(data){
    const UpDataOneLine = axios({
        url: 'patrol/line/basic/update',
        method: 'POST',
        data
    })
    return UpDataOneLine
}
///zypatrol/line/delete

export function DeleteOneLine(data){
    const DeleteOneLine = axios({
        url: 'patrol/line/delete',
        method: 'POST',
        data
    })
    return DeleteOneLine
}

///zypatrol/line/get


export function OneLineDetail(params){
    const OneLineDetail = axios({
        url: 'patrol/line/get',
        method: 'get',
        params
    })
    return OneLineDetail
}
// /dict/selectDictListByParentCode?parentCode=1000240001
export function SelectDic(params){
    const SelectDic = axios({
        url: 'dict/selectDictListByParentCode',
        method: 'get',
        params
    })
    return SelectDic
}

// /zypatrol/line/point/update

export function UpDataLine(data){
    const UpDataLine = axios({
        url: 'patrol/line/point/update',
        method: 'post',
        data
    })
    return UpDataLine
}
// patrol/network/update
export function UpDataNetWork(data){
    const UpDataNetWork = axios({
        url: 'patrol/network/update',
        method: 'post',
        data
    })
    return UpDataNetWork
}
//zypatrol/network/delete
export function deleteNetWork(data){
    const deleteNetWork = axios({
        url: 'patrol/network/delete',
        method: 'post',
        data
    })
    return deleteNetWork
}