<template>
    <div>
        <div class="LineLeft">
            <div :class="{'LineLeftTopClass':true,'LeftShift':LeftShiftBool}">
                <div class="LeftLeftTopButton">
                    <el-button  @click="AddPipLine" type="primary" icon='el-icon-plus' style="width:50%">
                        新增管网
                    </el-button>
                </div>
                <div style="text-align: center;margin-top:5px;">管网数量：{{AllLineGroup.length}}</div>
                <article v-for="(item,index) in AllLineGroup" :key="'PipGroup'+index" class="PipeLineInformation">
                    <p>
                        <span>管网名称：</span> {{item.networkName}}
                    </p>
                    <p>
                        <span>创建时间：</span> {{item.createTime}}
                    </p>
                    <p>
                        <span>创建人：</span> {{item.createBy}}
                    </p>
                    <p>
                        <span>管网长度：</span> {{item.length}}km
                    </p>
                    <div class="itemBtnGroop">
                        <el-checkbox @change="(val)=>PipLineStateChange(val,item)" v-model="item.newWorkBool" :label="item.id" name="open">启用管网</el-checkbox>
                        <p>
                            <span @click="toLineDetail(item)">进入</span>
                            <i @click="ShowNetWork(item)" :class="{'iconfont':true,'icon-xianshi':item.ShowLine,'icon-yincang':!item.ShowLine}"></i>
                            <i @click="editDataFun(item)" class="iconfont icon-bianji"></i>
                            <i @click="deleteDataFun(item)" class="iconfont icon-tubiao-"></i>
                        </p>
                    </div>
                </article>
            </div>
            <div class="PipeLineInformationDetail">
                <div class="LeftLeftTopButton">
                    <el-button  type="primary" @click="toLineDetail(false)" style="width:35%">
                        返回
                    </el-button>
                    <el-button  type="primary" @click="addoneLine" style="width:35%;text-align: center;margin-left:20px;    ">
                        添加管线
                    </el-button>
                </div>
                <div style="text-align: center;margin-top:5px;">管网名称：{{AllLineGroupName}}</div>
                <article @click="ClickOneLine(item,index)" v-for="(item,index) in LineOneList" :key="'oneline'+index" :class="{'PipeLineInformation':true,'SelectLineClass':SelectOneLine == index}"   style="overflow: hidden;margin-top:0px;">
                    <p>
                        <span >管线名称：</span> {{item.lineName}}
                    </p>
                    <p>
                        <span>创建时间：</span> {{item.createTime}}
                    </p>
                    <p>
                        <span>创建人：</span> {{item.createBy}}
                    </p>
                    <p>
                        <span>管线长度：</span> {{item.length}}km
                    </p>
                    <div class="itemBtnGroop"  style="float:right;margin-top:0px;">
                        <p @click="DeleteOneLineAjax(item)">
                            <i class="iconfont icon-tubiao-"></i>
                        </p>
                    </div>
                </article>

            </div>
        </div>

        <el-dialog
                :visible.sync="addOneLineBool"
                title="新增管线"
                @closed="addOneLineCloseFun"
        >
            <el-form style="margin-right:20px;">
                <el-form-item  label-width="100px"  label="管网名称">
                    <el-input  size="mini" v-model="addLineData.lineName"></el-input>
                </el-form-item>
                <el-form-item  label-width="100px"  label="使用状态">
                    <el-radio v-model="addLineData.useStatus" :label="0">在建</el-radio>
                    <el-radio v-model="addLineData.useStatus" :label="1">停用</el-radio>
                    <el-radio v-model="addLineData.useStatus" :label="2">在用</el-radio>
                    <el-radio v-model="addLineData.useStatus" :label="3">规划</el-radio>
                </el-form-item>
                <!-- <el-form-item  label-width="100px"  label="燃气类型">
                    <el-radio v-model="addLineData.gasType" :label="0">煤气</el-radio>
                    <el-radio v-model="addLineData.gasType" :label="1">天然气</el-radio>
                    <el-radio v-model="addLineData.gasType" :label="2">其他</el-radio>
                </el-form-item> -->
                <el-form-item  label-width="100px"  label="压力级别">
                    <el-radio v-model="addLineData.stressLevel" :label="0">高压</el-radio>
                    <el-radio v-model="addLineData.stressLevel" :label="1">低压</el-radio>
                    <el-radio v-model="addLineData.stressLevel" :label="2">中压</el-radio>
                    <el-radio v-model="addLineData.stressLevel" :label="3">次高压</el-radio>
                </el-form-item>
                <el-form-item  label-width="100px"  label="材质">
                    <el-radio v-model="addLineData.material" :label="0">铸铁</el-radio>
                    <el-radio v-model="addLineData.material" :label="1">其他</el-radio>
                    <el-radio v-model="addLineData.material" :label="2">PE</el-radio>
                    <el-radio v-model="addLineData.material" :label="3">PVC</el-radio>
                </el-form-item>
                <el-form-item  label-width="100px"  label="埋设方式">
                    <el-radio v-model="addLineData.buriedType" :label="0">架空</el-radio>
                    <el-radio v-model="addLineData.buriedType" :label="1">地下</el-radio>
                </el-form-item>
            </el-form>
            <div style="width:100%;text-align: center">
                <el-button @click="submitLine">
                    提交
                </el-button>
                <el-button @click="addOneLineBool = false ">
                    取消
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
                :visible.sync="AddLineBool"
                width="30%"
                @closed="AddLineClosed"
        >
            <el-form style="margin-right:20px;">
                <el-form-item  label-width="100px"  label="管网名称">
                    <el-input  size="mini" v-model="PipSet.networkName"></el-input>
                </el-form-item>
              <el-form-item  label-width="100px"  label="使用状态">
                <el-radio v-model="PipSet.networkStatus" :label="0">停用</el-radio>
                <el-radio v-model="PipSet.networkStatus" :label="1">启用</el-radio>
              </el-form-item>
                <el-form-item  label-width="100px"  label="备注">
                    <el-input :rows="4" type="textarea" size="mini" v-model="PipSet.networkRemark"></el-input>
                </el-form-item>
            </el-form>
          <div style="width:100%;text-align: center">
            <el-button @click="saveNewLinenetwork">
              保存
            </el-button>
            <el-button @click="AddLineBool = false">
              取消
            </el-button>
          </div>
        </el-dialog>

    </div>
</template>

<script>
    import {
      GetPiplineGroup,UpPipStatus,
      LineDetailList,UpDataOneLine,
      DeleteOneLine,OneLineDetail,UpDataNetWork,deleteNetWork
    } from '@/RequestPort/equipment/line'
    export default {
        name: "LineLeft",
        props:[
            'AllGroup'
        ],
        mounted() {
          this.RequestPiplineGroup()
        },
        data(){
            return {
                SelectOneLine:null,
                openPieline:[],
                LeftShiftBool:false,
                AddLineBool:false,
                PipSet:{
                  networkName:'',
                  networkStatus:"",
                  networkRemark:"",
                },
                RequestLineGroupData:{
                    current:1,
                    size:10000,
                },
                AllLineGroup:[],
                addOneLineBool:false,
                LineOneList:[],
                AllLineGroupName:'',
                addLineData:{
                    buriedType: 0,
                    diameter: 0,
                    gasType: 0,
                    id: '',
                    lineName: "",
                    material: 0,
                    networkId: 0,
                    stressLevel: 0,
                    styleColor: "#3366FF",
                    useStatus: 0,
                },
                editDataBool:false,

            }
        },
        watch:{

        },
        methods:{
          AddLineClosed(){
            this.PipSet={
                  networkName:'',
                  networkStatus:"",
                  networkRemark:"",
            }
          },
          addOneLineCloseFun(){},
          ShowNetWork(e){
            e.ShowLine = !e.ShowLine
            this.$forceUpdate()
            this.$emit('ChangeNetWork',this.AllLineGroup)
          },
          deleteDataFun(e){
            this.$confirm('此操作将永久删除该管网, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              closeOnClickModal:false,
              type: 'warning'
            }).then(() => {
              deleteNetWork({id:e.id}).then((e)=>{
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
                this.RequestPiplineGroup()
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除'
              });
            });
          },
          editDataFun(e){
            this.PipSet = e
            this.AddLineBool = !this.AddLineBool
          },
          saveNewLinenetwork(){
            UpDataNetWork(this.PipSet).then((e)=>{
              this.$message.success('操作成功')
              this.AddLineBool = false
              this.RequestPiplineGroup()
            })
          },
          DeleteOneLineAjax(e){
                this.$confirm('此操作将永久删除该管线, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    closeOnClickModal:false,
                    type: 'warning'
                }).then(() => {
                    DeleteOneLine({id:e.id}).then((e)=>{
                        LineDetailList({id:this.addLineData.networkId}).then((e)=>{
                                this.$message({
                                    type: 'success',
                                    message: '删除成功!'
                                });
                                this.LineOneList =e.data
                            })
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
          ClickOneLine(item,index){
                this.SelectOneLine = index
                OneLineDetail({id:item.id}).then((e)=>{
                    e.data.networkId = this.addLineData.networkId
                    this.$emit('LineDetailData',e.data)
                })
            },
          submitLine(e){
                UpDataOneLine(this.addLineData).then((e)=>{
                    LineDetailList({id:this.addLineData.networkId}).then((e)=>{
                        this.$message.success('添加成功')
                        this.addoneLine()
                        this.LineOneList =e.data
                    })
                })
            },
          addoneLine(){
                this.addOneLineBool = !this.addOneLineBool
            },
          RequestPiplineGroup(){
              GetPiplineGroup(this.RequestLineGroupData).then((e)=>{
                  this.AllLineGroup = e.data.records
                  this.AllLineGroup.forEach((item,index)=>{
                      item.ShowLine = false
                      if(item.networkStatus == 1){
                          this.$set(item,'newWorkBool',true)
                      }else{
                          this.$set(item,'newWorkBool',false)
                      }
                  })
              })
          },
          PipLineStateChange(e,a){
                let Status
                if(e == true){
                    Status = 1
                }else{
                    Status = 0
                }
                UpPipStatus({id:a.id,networkStatus:Status}).then((e)=>{
                    this.RequestPiplineGroup()
                })
          },
          AddPipLine(e){
                this.AddLineBool = !this.AddLineBool
          },
          LineDetailListAjax(){
                LineDetailList({id:this.addLineData.networkId}).then((e)=>{
                    this.LineOneList =e.data
                    this.LeftShiftBool = true
                })
            },
          toLineDetail(e){
                if(e){
                    this.AllLineGroupName  = e.networkName
                    this.addLineData.networkId = e.id
                    this.LineDetailListAjax()
                }else{
                    this.LeftShiftBool = !this.LeftShiftBool
                    this.$emit('CloseOneLine',{})
                    this.SelectOneLine = null
                }
            },
        },
    }
</script>

<style>

</style>